import ParseAuth from "./common/parseAuth";
import React from "react";
import { Admin, Resource } from "react-admin";
import CertificateEdit from "./certificates/CertificateEdit";
import CertificateList from "./certificates/CertificateList";
import MyLayout from "./common/MyLayout";
import myTheme from "./common/myTheme";
import dataProvider from "./dataProvider";
import InvoiceEdit from "./invoices/InvoiceEdit";
import InvoiceList from "./invoices/InvoiceList";

const parseConfig = {
  URL: process.env.REACT_APP_PARSE_SERVER_URL,
  JAVASCRIPT_KEY: process.env.REACT_APP_PARSE_SERVER_JS_KEY,
  APP_ID: process.env.REACT_APP_PARSE_SERVER_APP_ID,
};

const dp = dataProvider(
  process.env.REACT_APP_ELASTIC_HOST,
  process.env.REACT_APP_ELASTIC_AUTH,
  parseConfig
);
const authProvider = ParseAuth(parseConfig);

const App = () => (
  <Admin
    theme={myTheme}
    authProvider={authProvider}
    dataProvider={dp}
    layout={MyLayout}
  >
    <Resource
      name="certificates"
      list={CertificateList}
      edit={CertificateEdit}
    ></Resource>
    <Resource name="invoices" list={InvoiceList} edit={InvoiceEdit}></Resource>
  </Admin>
);

export default App;
