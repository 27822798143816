import _ from "lodash";
import React, { useEffect, useState } from "react";

export default function StatusField({ source, record = {} }) {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (!record || !source) return;

    setStatus(
      _.chain(_.get(record, source, ""))
        .replace("_", " ")
        .replace("verification", "")
        .startCase()
        .trim()
        .value()
    );
  }, [record, source]);

  return <span>{status}</span>;
}
