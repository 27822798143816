import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import React from "react";
import { AppBar, useRedirect } from "react-admin";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    // flex: 1,
  },
});

const MyAppBar = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
        style={{ paddingLeft: "12px" }}
      />
      <span className={classes.spacer} />
      <Tooltip title="Invoices">
        <IconButton
          color="inherit"
          onClick={() => {
            redirect("/invoices");
          }}
        >
          <InsertDriveFileIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Certificates">
        <IconButton
          color="inherit"
          onClick={() => {
            redirect("/certificates");
          }}
        >
          <VerifiedUserIcon />
        </IconButton>
      </Tooltip>
    </AppBar>
  );
};

export default MyAppBar;
