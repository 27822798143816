import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { camelCase } from "lodash";
import RcViewer from "rc-viewer";
import React, { useEffect, useState } from "react";
import { PDFObject } from "react-pdfobject";

const useStyles = makeStyles({
  cardContent: { height: 640, padding: "0 !important" },
  rcImage: {
    display: "none",
  },
});

export default function DocViewer({ record, source, src, height }) {
  const classes = useStyles();
  const [url, setUrl] = useState(null);
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    const prefix = process.env.REACT_APP_S3_PROXY_URL;
    if (Boolean(src)) {
      setIsImage(!src.endsWith(".pdf"));
      setUrl(Boolean(src) && !src.startsWith("http") ? `${prefix}${src}` : src);
      return;
    }

    if (
      !record ||
      !source ||
      !record[source] ||
      typeof record[source] !== "string"
    ) {
      return;
    }

    setIsImage(!record[source].endsWith(".pdf"));
    setUrl(
      Boolean(record[source]) && !record[source].startsWith("http")
        ? `${prefix}${record[source]}`
        : record[source]
    );
  }, [record, source, src]);

  return (
    <Card>
      <CardContent className={classes.cardContent} style={{ height }}>
        {url && isImage && (
          <RcViewer
            options={{
              inline: true,
              minHeight: height || 300,
              toolbar: {
                zoomIn: 4,
                zoomOut: 4,
                reset: 4,
                oneToOne: 4,
                prev: 0,
                play: 0,
                next: 0,
                rotateLeft: 4,
                rotateRight: 4,
                flipHorizontal: 4,
                flipVertical: 4,
              },
            }}
          >
            <img src={url} className={classes.rcImage} alt="" />
          </RcViewer>
        )}

        {url && !isImage && (
          <PDFObject
            height={`${height || 640}px`}
            width="100%"
            url={url}
            containerId={camelCase(url)}
          />
        )}
      </CardContent>
    </Card>
  );
}
