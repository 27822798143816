import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import jsonExport from "jsonexport/dist";
import _ from "lodash";
import millify from "millify";
import React from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  downloadCSV,
  Filter,
  List,
  SelectInput,
  TextField,
  TextInput,
  useQuery,
} from "react-admin";
import StatusField from "../common/StatusField";

function InvoiceFilter(props) {
  const timestamp = (date) => {
    return `${date.split(".")[0]}Z`;
  };

  const dateParser = (v) => {
    return v ? timestamp(new Date(v).toISOString()) : null;
  };

  return (
    <Filter {...props}>
      <DateInput
        label="Audited From"
        source="AUDITED_AFTER"
        parse={dateParser}
      />
      <DateInput
        label="Audited To"
        source="AUDITED_BEFORE"
        parse={dateParser}
      />
      <TextInput label="Invoice No." source="invoice_normalized_data.invoiceNo" />
      <TextInput label="Data Provider" source="data_provider" />
      <TextInput label="KP No." source="invoice_normalized_data.kpcNo" />
      <DateInput label="Invoice Date" source="invoice_source_data.INVOICEDATE" />
      <SelectInput
        label="Status"
        source="audit_status"
        choices={[
          { id: "verification_passed", name: "Passed" },
          { id: "verification_failed", name: "Failed" },
          { id: "not_verified", name: "Not Verified" },
        ]}
      />
    </Filter>
  );
}

const exporter = (invoices) => {
  const invoicesForExport = invoices.map((invoice) => {
    const auditInfo = _.get(invoice, "invoice_manual_audit_info", {});
    const sourceData = _.get(invoice, "invoice_source_info", {});
    return {
      ...sourceData,
      ...auditInfo,
      FAILED_CHECKS: _.get(auditInfo, "FAILED_CHECKS", []).join(","),
    };
  });
  jsonExport(invoicesForExport, (err, csv) => {
    downloadCSV(csv, "invoices");
  });
};

const rowStyle = (record, index) => ({
  backgroundColor:
    _.get(record, "invoice_manual_audit_info.audit_status", "") ===
    "not_verified"
      ? "rgba(0, 0, 0, 0.09)"
      : _.get(record, "invoice_manual_audit_info.audit_status", "") ===
        "verification_passed"
      ? "#efe"
      : "#ffebee",
});

const OriginField = ({ source, record = {}, altSource }) => (
  <span style={{ color: _.get(record, source, null) ? undefined : "red" }}>
    {_.get(record, source, null) || _.get(record, altSource, "")}
  </span>
);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1.5),
    },
  },
}));

function InvoiceTitle({ verificationPassed, verificationFailed, notVerified }) {
  const classes = useStyles();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>Invoices</span>
      <div style={{ display: "flex", flex: 1 }}></div>
      <div className={classes.root}>
        {Boolean(verificationPassed) && (
          <Badge badgeContent={millify(verificationPassed)} color="primary">
            <ThumbUpIcon />
          </Badge>
        )}
        {Boolean(verificationFailed) && (
          <Badge badgeContent={millify(verificationFailed)} color="primary">
            <ThumbDownIcon />
          </Badge>
        )}
        {Boolean(notVerified) && (
          <Badge badgeContent={millify(notVerified)} color="primary">
            <HourglassFullIcon />
          </Badge>
        )}
      </div>
      <div style={{ display: "flex", flex: 1 }}></div>
    </div>
  );
}

export default function InvoiceList(props) {
  const { data } = useQuery({
    type: "count",
    resource: props.resource,
    payload: {},
  });

  return (
    <List
      {...props}
      title={<InvoiceTitle {...data} />}
      filters={<InvoiceFilter />}
      exporter={exporter}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit" rowStyle={rowStyle}>
        <TextField
          label="Invoice No."
          source="invoice_normalized_data.invoiceNo"
          sortable={false}
        />
        <TextField
          label="Data Provider"
          source="data_provider"
          sortable={false}
        />
        <OriginField
          label="Origin"
          source="invoice_source_data.ORIGIN"
          altSource="invoice_manual_audit_info.ORIGIN"
          sortable={false}
        />

        <TextField
          label="KP No."
          source="invoice_normalized_data.kpcNo"
          sortable={false}
        />
        <DateField
          label="Invoice Date"
          source="invoice_source_data.INVOICEDATE"
          sortable={false}
        />
        <StatusField
          label="Status"
          source="invoice_manual_audit_info.audit_status"
          sortable={false}
        />
        <DateField
          label="Audited On"
          source="invoice_manual_audit_info.AUDITED_ON"
          sortable={false}
          showTime
        />
      </Datagrid>
    </List>
  );
}
