import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import MultiDocViewer from "../common/MultiDocViewer";
import Summary from "../common/Summary";

const useStyles = makeStyles({
  root: {
    width: "74%",
    margin: "0 0.5em",
  },
  viewer: {
    width: "70%",
    float: "left",
    margin: "0 0.5em",
  },
  summary: {
    width: "25%",
    float: "left",
    margin: "0 0.5em",
  },
});

export default function InvoiceEditAside({ record, setRecord, ...others }) {
  const classes = useStyles();
  const skipFields = process.env.REACT_APP_INVOICE_HIDDEN_FIELDS || [];

  useEffect(() => {
    if (!record || !setRecord) return;

    setRecord(record);
  }, [record, setRecord]);

  return (
    <div className={classes.root}>
      <div className={classes.viewer}>
        <MultiDocViewer
          record={record}
          {...others}
          label="Attachments"
          source="DOCUMENTS"
        ></MultiDocViewer>
      </div>
      <div className={classes.summary}>
        <Summary
          record={record}
          {...others}
          skipFields={skipFields}
          source="invoice_source_data"
        ></Summary>
      </div>
    </div>
  );
}
