import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import DocViewer from "./DocViewer";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(1),
    paddingBottom: `${theme.spacing(1)}px !important`,
  },
}));

export default function MultiDocViewer({ record, source, label, ...others }) {
  const classes = useStyles();
  const [urls, setUrls] = useState([]);
  const [url, setUrl] = useState(undefined);

  useEffect(() => {
    if (!record || !source || !record[source]) {
      return;
    }

    if (typeof record[source] === "string") {
      setUrls([record[source]]);
      setUrl(record[source]);
      return;
    }

    setUrls(record[source]);
    setUrl(_.get(record, `${source}[0]`, undefined));
  }, [record, source]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <FormControl className={classes.formControl}>
            {urls && urls.length > 1 && (
              <InputLabel htmlFor="documents">{label}</InputLabel>
            )}

            {urls && urls.length > 1 && (
              <Select
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                inputProps={{
                  name: "documents",
                  id: "documents",
                }}
              >
                {urls.map((i) => (
                  <MenuItem key={i} value={i}>
                    {i.split("/")[i.split("/").length - 1].split("?")[0]}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </CardContent>
      </Card>
      <DocViewer {...others} height={542} src={url}></DocViewer>
    </>
  );
}
