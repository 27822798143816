import { makeStyles } from "@material-ui/core/styles";
import { get, lowerCase, sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";
import CheckInput from "../common/check-input";
import CertificateEditAside from "./CertificateEditAside";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  simpleForm: {
    maxHeight: 702,
    overflow: "scroll",
    display: "flex",
    flex: 1,
    height: "100%",
    flexDirection: "column",
  },
});

function CertificateToolbar({ pristine, ...props }) {
  return (
    <Toolbar pristine={pristine} {...props}>
      <SaveButton disabled={pristine} />
      <div style={{ display: "flex", flex: 1 }}></div>
    </Toolbar>
  );
}

export default function CertificateEdit(props) {
  const classes = useStyles();
  const [record, setRecord] = useState(null);
  const [checks, setChecks] = useState(null);

  useEffect(() => {
    if (!record) return;

    const skipChecks = [
      "AUDITED_BY",
      "AUDITED_ON",
      "AUDITED_REMARKS",
      "audit_status",
      "FAILED_CHECKS",
    ];

    const object = get(record, "grading_manual_audit_info", {});

    const objects = Object.keys(object)
      .filter((key) => skipChecks.indexOf(key) === -1)
      .map((key) => ({
        label: lowerCase(key),
        source: key,
      }));

    const items = sortBy(objects, ["label", "source"]);

    setChecks(items);
  }, [record]);

  return (
    <Edit {...props} aside={<CertificateEditAside setRecord={setRecord} />}>
      <SimpleForm
        className={classes.simpleForm}
        submitOnEnter={false}
        redirect={false}
        toolbar={<CertificateToolbar />}
      >
        {checks &&
          checks.map((item, i) => (
            <CheckInput
              key={i}
              {...item}
              source={`grading_manual_audit_info.${item.source}`}
              label={`${i + 1}. ${item.label.replace("gsi", "").trim()}`}
            ></CheckInput>
          ))}

        <TextInput
          multiline
          label="Remarks"
          fullWidth={true}
          source="grading_manual_audit_info.AUDIT_REMARKS"
        />
      </SimpleForm>
    </Edit>
  );
}
