import { makeStyles } from "@material-ui/core/styles";
import { get, lowerCase, sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";
import CheckInput from "../common/check-input";
import InvoiceEditAside from "./InvoiceEditAside";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  simpleForm: {
    overflow: "scroll",
    display: "flex",
    flex: 1,
    height: "100%",
    maxHeight: 638,
    flexDirection: "column",
  },
});

function InvoiceToolbar({ pristine, ...props }) {
  return (
    <Toolbar pristine={pristine} {...props}>
      <SaveButton disabled={pristine} />
      <div style={{ display: "flex", flex: 1 }}></div>
    </Toolbar>
  );
}

export default function InvoiceEdit(props) {
  const classes = useStyles();
  const [checks, setChecks] = useState(null);
  const [record, setRecord] = useState(null);
  const [isManual, setIsManual] = useState(false);

  useEffect(() => {
    if (!record) return;

    const skipChecks = [
      "AUDITED_BY",
      "AUDITED_ON",
      "AUDITED_REMARKS",
      "audit_status",
      "FAILED_CHECKS",
      "ORIGIN",
    ];
    const object = get(record, "invoice_manual_audit_info", {});
    console.log(object)
    setIsManual(get(record, "invoice_source_data.ORIGIN", true));

    const objects = Object.keys(object)
      .filter((key) => skipChecks.indexOf(key) === -1)
      .map((key) => ({
        label: lowerCase(key),
        source: key,
      }));

    const items = sortBy(objects, ["label", "source"]);

    setChecks(items);
  }, [record]);

  return (
    <Edit {...props} aside={<InvoiceEditAside setRecord={setRecord} />}>
      <SimpleForm
        className={classes.simpleForm}
        submitOnEnter={false}
        redirect={false}
        toolbar={<InvoiceToolbar />}
      >
        {isManual === "" && (
          <TextInput
            label="1. What is the invoice origin?"
            source="invoice_manual_audit_info.ORIGIN"
          />
        )}
        {checks &&
          checks.map((item, i) => (
            <CheckInput
              key={i}
              {...item}
              source={`invoice_manual_audit_info.${item.source}`}
              label={`${isManual === "" ? i + 2 : i + 1}. ${item.label}`}
            ></CheckInput>
          ))}

        <TextInput
          multiline
          label="Remarks"
          fullWidth={true}
          source="invoice_manual_audit_info.AUDIT_REMARKS"
        />
      </SimpleForm>
    </Edit>
  );
}
