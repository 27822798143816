import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import jsonExport from "jsonexport/dist";
import _ from "lodash";
import millify from "millify";
import React from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  downloadCSV,
  Filter,
  List,
  SelectInput,
  TextField,
  TextInput,
  useQuery,
} from "react-admin";
import CustomPagination from "../common/CustomPagination";
import StatusField from "../common/StatusField";

function CertificateFilter(props) {
  const timestamp = (date) => {
    return `${date.split(".")[0]}Z`;
  };

  const dateParser = (v) => {
    return v ? timestamp(new Date(v).toISOString()) : null;
  };

  return (
    <Filter {...props}>
      {/* <DateInput label="Received On" source="RECEIVED_ON" /> */}

      <DateInput
        label="Audited From"
        source="AUDITED_AFTER"
        parse={dateParser}
      />
      <DateInput
        label="Audited To"
        source="AUDITED_BEFORE"
        parse={dateParser}
      />
      <TextInput label="Lot Number" source="LOTNUMBER" />
      <TextInput label="Data Provider" source="data_provider" />
      <TextInput label="Report Number" source="REPORT_NO" />
      <TextInput label="Packet ID" source="PACKETID" />
      <DateInput label="Issue Date" source="ISSUEDATE" />
      <SelectInput
        label="Status"
        source="audit_status"
        choices={[
          { id: "verification_passed", name: "Passed" },
          { id: "verification_failed", name: "Failed" },
          { id: "not_verified", name: "Not Verified" },
        ]}
      />
    </Filter>
  );
}

const rowStyle = (record, index) => ({
  backgroundColor:
    _.get(record, "grading_manual_audit_info.audit_status", "") ===
    "not_verified"
      ? "rgba(0, 0, 0, 0.09)"
      : _.get(record, "grading_manual_audit_info.audit_status", "") ===
        "verification_passed"
      ? "#efe"
      : "#ffebee",
});

const exporter = (certificates) => {
  const certificatesForExport = certificates.map((certificate) => {
    const auditInfo = _.get(certificate, "grading_manual_audit_info", {});
    const sourceData = _.get(certificate, "grading_source_data", {});
    return {
      ...sourceData,
      ...auditInfo,
      FAILED_CHECKS: _.get(auditInfo, "FAILED_CHECKS", []).join(","),
    };
  });
  jsonExport(certificatesForExport, (err, csv) => {
    downloadCSV(csv, "certificates");
  });
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1.5),
    },
  },
}));

function CertificateTitle({
  verificationPassed,
  verificationFailed,
  notVerified,
}) {
  const classes = useStyles();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>Certificates</span>
      <div style={{ display: "flex", flex: 1 }}></div>
      <div className={classes.root}>
        {Boolean(verificationPassed) && (
          <Badge badgeContent={millify(verificationPassed)} color="primary">
            <ThumbUpIcon />
          </Badge>
        )}
        {Boolean(verificationFailed) && (
          <Badge badgeContent={millify(verificationFailed)} color="primary">
            <ThumbDownIcon />
          </Badge>
        )}
        {Boolean(notVerified) && (
          <Badge badgeContent={millify(notVerified)} color="primary">
            <HourglassFullIcon />
          </Badge>
        )}
      </div>
      <div style={{ display: "flex", flex: 1 }}></div>
    </div>
  );
}

export default function CertificateList(props) {
  const { data } = useQuery({
    type: "count",
    resource: props.resource,
    payload: {},
  });

  return (
    <List
      {...props}
      title={<CertificateTitle {...data} />}
      filters={<CertificateFilter />}
      exporter={exporter}
      bulkActionButtons={false}
      pagination={<CustomPagination />}
    >
      <Datagrid rowClick="edit" rowStyle={rowStyle}>
        {/* <TextField label="ID" source="object_id" sortable={false} /> */}
        <TextField
          label="Data Provider"
          source="data_provider"
          sortable={false}
        />
        <TextField
          label="Certifier"
          source="grading_source_data.CERTIFIER"
          sortable={false}
        />
        <TextField
          label="Report Number"
          source="grading_source_data.REPORT_NO"
          sortable={false}
        />
        <TextField
          label="Lot Number"
          source="grading_source_data.LOTNUMBER"
          sortable={false}
        />
        <TextField
          label="Packet ID"
          source="grading_source_data.PACKETID"
          sortable={false}
        />
        <DateField
          label="Issue Date"
          source="grading_source_data.ISSUEDATE"
          sortable={false}
        />
        <StatusField
          label="Status"
          source="grading_manual_audit_info.audit_status"
          sortable={false}
        />
        <DateField
          label="Audited On"
          source="grading_manual_audit_info.AUDITED_ON"
          sortable={false}
          showTime
        />
      </Datagrid>
    </List>
  );
}
