import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get, upperCase } from "lodash";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles({
  card: {
    height: 638,
  },
  cardContent: {
    maxHeight: 600,
    overflow: "scroll",
  },
  match: {
    color: "green",
  },
  doesNotMatch: {
    color: "red",
  },
});

function SummaryField({ record, item, resource }) {
  const classes = useStyles();
  const [autoValue, setAutoValue] = useState(null);
  const [key, setKey] = useState(null);
  const [value, setValue] = useState(null);
  const [matches, setMatches] = useState(null);

  useEffect(() => {
    if (!resource || !record || !key) return;

    const prefix = resource === "certificates" ? "grading" : "invoice";

    const failedChecks =
      get(record, [`${prefix}_automated_audit_info`, "FAILED_CHECKS"], []) ||
      [];

    const av = get(record, [`${prefix}_automated_data`, key], null);

    setAutoValue(av);
    setMatches(Boolean(av) ? failedChecks.indexOf(key) < 0 : null);
  }, [resource, record, key]);

  useEffect(() => {
    if (!item || !item.itemKey || !item.itemValue) return;
    setKey(item.itemKey);
    setValue(item.itemValue);
  }, [item]);

  return (
    <div>
      {value && (
        <div
          className={
            matches !== null
              ? matches
                ? classes.match
                : classes.doesNotMatch
              : null
          }
        >
          <Typography variant="body2">
            <strong>{upperCase(key)}:</strong>
          </Typography>
          <Typography variant="body2">
            {value}{" "}
            {matches !== null && !matches && autoValue && `(${autoValue})`}
          </Typography>
          <br />
        </div>
      )}
    </div>
  );
}

export default function Summary({ record, skipFields = [], source, resource }) {
  const classes = useStyles();

  const [items, setItems] = useState(null);

  useEffect(() => {
    if (!record || !source) return;
    console.log(source)
    console.log(record)
    const temp = Object.keys(record[source])
      .filter((i) => skipFields.indexOf(i) < 0)
      .sort()
      .map((i) => ({ itemKey: i, itemValue: record[source][i] }));

    setItems(temp);
  }, [record, skipFields, source]);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6">Summary</Typography>
        <br />
        {items &&
          items.map((item) => (
            <SummaryField
              record={record}
              resource={resource}
              key={items.indexOf(item)}
              item={item}
            />
          ))}
      </CardContent>
    </Card>
  );
}
