import bodybuilder from "bodybuilder";
import diff from "deep-diff";
import delay from "delay";
import elasticsearch from "elasticsearch";
import _ from "lodash";
import moment from "moment";
import { GET_LIST, GET_MANY_REFERENCE, GET_ONE, UPDATE } from "react-admin";
import Parse from "parse";
const AWS = require('aws-sdk');

async function getURL(s3params) {
  try {
    const s3 = new AWS.S3({apiVersion: '2006-03-01', 
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, 
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, 
    region: 'ap-south-1'}, );
    const url = await s3.getSignedUrl('getObject', s3params);
    return url;
  } catch (e) {
    throw new Error(`Could not retrieve file from S3: ${e.message}`)
  }
}

export default (host, httpAuth) => {
  return async (type, resource, params) => {
    const currentUser = Parse.User.current();
    const username = currentUser.get("username");
    const prefix = resource === "certificates" ? "grading" : "invoice";

    const elastic_index = process.env.REACT_APP_ELASTIC_INDICES;

    console.log(type, resource, params);

    // const script = {
    //   "script": {
    //     "lang": "painless",
    //     "params": {
    //       "value": true
    //     },
    //     "source": ""//boolean compare(Supplier s, def v) {return s.get() == v;}compare(() -> { (doc['grading_normalized_data.certifier.keyword'].value == \"GIA\" && doc['CERTIFICATE_automated_audit_info.audit_status.keyword'].value == \"verification_failed\") || (doc['grading_normalized_data.certifier.keyword'].value != \"GIA\" && doc['CERTIFICATE_automated_audit_info.audit_status.keyword'].value == \"not_verified\") }, params.value);"
    //   }
    // }

    // TODO: fix this once an auto auditor drops in for invoices
    // const AUTO_AUDIT =
      // resource === "certificates" ? "verification_failed" : "";

    const timestamp = () => {
      return `${moment().toISOString().split(".")[0]}Z`;
    };
    console.log("host: " + host)
    const client = new elasticsearch.Client({
      host,
      apiVersion: "7.2"
    });

    var mainBody = bodybuilder()
    if (resource === "certificates") {
      mainBody.notFilter(
        "match",
        "reference.certifier.keyword",
        "GIA"
      ).query(
        "match",
        "doc_type.keyword",
        "grading"
      )
    }else {
      mainBody.query(
        "match",
        "doc_type.keyword",
        "invoice"
      )
    }

    var countBody = bodybuilder()
    if (resource === "certificates") {
      countBody.notFilter(
        "match",
        "reference.certifier.keyword",
        "GIA"
      ).query(
        "match",
        "doc_type.keyword",
        "grading"
      )
    }else {
      countBody.query(
        "match",
        "doc_type.keyword",
        "invoice"
      )
    }
   
    if (resource === "certificates") {
      mainBody.notQuery(
        "match",
        "source_attachment.imageUrl.keyword",
        ""
      );
      countBody.notQuery(
        "match",
        "source_attachment.imageUrl.keyword",
        ""
      );
    }

    switch (type) {
      case GET_MANY_REFERENCE:
      case GET_LIST: {
        console.log("Getting list")
        const { page, perPage } = params.pagination;
        const { filter } = params;
        const skipDateFilters = ["AUDITED_BEFORE", "AUDITED_AFTER"];

        const normalizedFilters = ["KPNUMBER"];

        const baseFilters = ["data_provider"]

        const auditFilters = ["audit_status"];

        const normalDateFilters = ["INVOICEDATE"];
        const sourceDateFilters = ["ISSUEDATE"];

        console.log(filter);
        Object.keys(filter).forEach((key) => {
          if (skipDateFilters.indexOf(key) > -1) {
            return;
          }

          if (auditFilters.indexOf(key) > -1) {
            console.log("key:" + key);
            mainBody.query(
              "match",
              `${prefix}_manual_audit_info.${key}`,
              filter[key]
            );
            countBody.query(
              "match",
              `${prefix}_manual_audit_info.${key}`,
              filter[key]
            );
            return;
          }

          if (normalDateFilters.indexOf(key) > -1) {
            mainBody.query(
              "match",
              `${prefix}_normalized_data.${key}`,
              filter[key]
            );
            countBody.query(
              "match",
              `${prefix}_normalized_data.${key}`,
              filter[key]
            );
            return;
          }
          if (sourceDateFilters.indexOf(key) > -1) {
            mainBody.query(
              "match",
              `${prefix}_source_data.${key}`,
              filter[key]
            );
            countBody.query(
              "match",
              `${prefix}_source_data.${key}`,
              filter[key]
            );
            return;
          }

          if (baseFilters.indexOf(key) > -1) {
            mainBody.query(
              "match",
              `${key}`,
              filter[key]
            );
            countBody.query(
              "match",
              `${key}`,
              filter[key]
            );
            return;
          }

          if (normalizedFilters.indexOf(key) > -1) {
            mainBody.query(
              "match_phrase_prefix",
              `${prefix}_normalized_data.${key}`,
              filter[key]
            );
            countBody.query(
              "match_phrase_prefix",
              `${prefix}_normalized_data.${key}`,
              filter[key]
            );
            return;
          }

          mainBody.query("match_phrase_prefix", `${prefix}_source_data.${key}`, filter[key]);
          countBody.query(
            "match_phrase_prefix",
            `${prefix}_source_data.${key}`,
            filter[key]
          );
        });

        // const dateBefore = _.get(filter, "AUDITED_BEFORE", undefined);
        // const dateAfter = _.get(filter, "AUDITED_AFTER", undefined);

        // let dateFilter = {};

        // if (dateBefore) dateFilter.lte = dateBefore;
        // if (dateAfter) dateFilter.gte = dateAfter;

        // if (!_.isEmpty(dateFilter)) {
        //   mainBody.query(
        //     "range",
        //     `${prefix}_manual_audit_info.AUDITED_ON`,
        //     dateFilter
        //   );
        //   countBody.query(
        //     "range",
        //     `${prefix}_manual_audit_info.AUDITED_ON`,
        //     dateFilter
        //   );
        // }

        const { count } = await client.count({
          index: elastic_index,
          body: countBody.build(),
        });
        console.log(count)
        mainBody.size(perPage);
        mainBody.from(perPage * (page - 1));

        // mainBody.sort(`RECEIVED_ON`, "asc").sort(`_id`, "asc");
        // countBody.sort(`RECEIVED_ON`, "asc").sort(`_id`, "asc");

        const results = await client.search({
          index: elastic_index,
          body: mainBody.build(),
        });

        return {
          total: count,
          data: results.hits.hits.map((o) => ({
            id: o._id,
            ...o._source,
          })),
        };
      }
      case GET_ONE: {
        const { id } = params;
        const item = await client.get({ id, type: "_doc", index: elastic_index });

        let data = { id: item._id, index: item._index, ...item._source };
        const s3_bucket = "client-data-raw"
        const s3_prefix = "harikrishna/diamonds"
        const invoiceImageSrc = _.get(
          item,
          // `_source.${prefix}_NORMALIZED_DATA.INVOICE_IMAGE`,
          `_source.source_attachment`,
          ""
        );
        // const invoiceImages = invoiceImageSrc
        //   ? typeof invoiceImageSrc === "string"
        //     ? [invoiceImageSrc]
        //     : invoiceImageSrc
        //   : [];
        const invoiceImages = await invoiceImageSrc.reduce(async function (acc, cur) {
          if (cur.appType === 'INVOICE IMAGE') {
            const s3params = {Bucket: s3_bucket, Key: s3_prefix + cur.imageUrl};
            const url = await getURL(s3params);
            (await acc).push(url)
          }
          return (await acc)
        }, [])
        console.log(invoiceImages)


        const kpImageSrc = _.get(
          item,
          // `_source.${prefix}_NORMALIZED_DATA.KP_IMAGE`,
          `_source.source_attachment`,
          []
        );
        const kpImages = await kpImageSrc.reduce(async function (acc, cur) {
          if (cur.appType === 'KP IMAGE') {
            const s3params = {Bucket: s3_bucket, Key: s3_prefix + cur.imageUrl};
            const url = await getURL(s3params);
            (await acc).push(url)
          }
          return (await acc)
        }, [])

        // const kpImages = kpImageSrc
        //   ? typeof kpImageSrc === "string"
        //     ? [kpImageSrc]
        //     : kpImageSrc
        //   : [];

        let certImagesSrc = _.get(
          item,
          `_source.source_attachment`,
          []
        );
        // certImagesSrc = certImagesSrc.replace("\\", "/")
        // const s3params = {Bucket: 'sheetal-data-everledger', Key: "evl_sheetal_15082020/" + certImagesSrc};
        // const url = await getURL(s3params);
        // certImagesSrc = url
        // const certImages = certImagesSrc
        //   ? typeof certImagesSrc === "string"
        //     ? [certImagesSrc]
        //     : certImagesSrc
        //   : [];

        const certImages = certImagesSrc.reduce(function (acc, cur) {
          if (cur.appType === 'GRADING') {
            acc.push(cur.imageUrl)
          }
          return acc
        }, [])

        // const polishedImageSrc = _.get(
        //   item,
        //   `_source.grading_source_data.IMAGEURL`,
        //   null
        // );
        // const polishedImages = polishedImageSrc
        //   ? typeof polishedImageSrc === "string"
        //     ? [polishedImageSrc]
        //     : polishedImageSrc
        //   : [];

        const DOCUMENTS = [
          ...invoiceImages,
          ...kpImages,
          ...certImages,
          // ...polishedImages,
        ];

        data = { ...data, DOCUMENTS };

        return { data };
      }

      case UPDATE: {
        const { id, data, previousData } = params;

        const differences = diff(previousData, data);

        if (!differences) return { data: { id, ...data } };

        const doc = {};

        differences.forEach((item) => {
          const path = _.get(item, "path[1]", null);
          const d = _.get(item, "rhs", null);

          if (!path || !d) return;

          doc[path] = d;
        });

        const manualAuditInfo = _.get(data, `${prefix}_manual_audit_info`, {});

        const ignoredKeys = [
          "FAILED_CHECKS",
          "AUDITED_BY",
          "AUDITED_REMARKS",
          "audit_status",
          "AUDITED_ON",
          "ORIGIN",
        ];

        const hasAnyPending = Object.keys(manualAuditInfo).filter(
          (key) =>
            ignoredKeys.indexOf(key) === -1 &&
            manualAuditInfo[key] === "not_verified"
        ).length
          ? true
          : false;

        const failedChecks = Object.keys(manualAuditInfo).filter(
          (key) =>
            ignoredKeys.indexOf(key) === -1 &&
            manualAuditInfo[key] === "verification_failed"
        );

        const hasFailed = failedChecks.length ? true : false;

        doc["audit_status"] = hasAnyPending
          ? "not_verified"
          : hasFailed
            ? "verification_failed"
            : "verification_passed";

        doc["AUDITED_ON"] = timestamp();

        doc["AUDITED_BY"] = username;

        doc["FAILED_CHECKS"] = failedChecks;

        const updatedDoc = { [`${prefix}_manual_audit_info`]: doc };

        await client.update({
          id,
          index: data.index,
          body: { doc: updatedDoc },
        });

        await delay(1000);

        return { data: { id, ...data, ...updatedDoc } };
      }
      case "count":
        console.log("Count")
        const { count: total } = await client.count({
          index: elastic_index,
          body: {},
        });
        console.log("total" + total)
        const { count: verificationPassed } = await client.count({
          index: elastic_index,
          body: bodybuilder()
            // .query(
            //   "match",
            //   `${prefix}_DATA_INTEGRITY_INFO.audit_status`,
            //   "verification_passed"
            // )
            .query(
              "match",
              `${prefix}_manual_audit_info.audit_status`,
              "verification_passed"
            )
            .notQuery(
              "match",
              prefix === "grading"
                ? `${prefix}_source_data.IMAGEURL`
                : `${prefix}_source_data`,
              ""
            )
            .build(),
        });

        const { count: verificationFailed } = await client.count({
          index: elastic_index,
          body: bodybuilder()
            // .query(
            //   "match",
            //   `${prefix}_DATA_INTEGRITY_INFO.audit_status`,
            //   "verification_passed"
            // )
            .query(
              "match",
              `${prefix}_manual_audit_info.audit_status`,
              "verification_failed"
            )
            .notQuery(
              "match",
              prefix === "grading"
                ? `${prefix}_source_data.IMAGEURL`
                : `${prefix}_source_data`,
              ""
            )
            .build(),
        });

        const { count: notVerified } = await client.count({
          index: elastic_index,
          body: bodybuilder()
            // .query(
            //   "match",
            //   `${prefix}_DATA_INTEGRITY_INFO.audit_status`,
            //   "verification_passed"
            // )
            .query(
              "match",
              `${prefix}_manual_audit_info.audit_status`,
              "not_verified"
            )
            .notQuery(
              "match",
              prefix === "grading"
                ? `${prefix}_source_data.IMAGEURL`
                : `${prefix}_source_data`,
              ""
            )
            .build(),
        });

        const data = {
          total,
          verificationPassed,
          verificationFailed,
          notVerified,
        };

        return { data };
      default: {
        throw Error("Method Not allowed!");
      }
    }
  };
};
