import { Typography } from "@material-ui/core";
import teal from "@material-ui/core/colors/teal";
import React from "react";
import { RadioButtonGroupInput } from "react-admin";

const CheckInput = ({ record, source, label, reference }) => {
  const cleanValue = record[reference] || false;
  const cleanInformation =
    cleanValue instanceof Date
      ? cleanValue.toLocaleString().split(",")[0]
      : cleanValue;

  const referenceLabel = !!cleanValue ? `( ${cleanInformation} )` : "";

  return (
    <div>
      <Typography variant="body1" display="block" style={{ maxWidth: "400px" }}>
        <b>{label}</b>
      </Typography>
      <Typography
        variant="caption"
        display="block"
        style={{ color: teal["500"] }}
      >
        <b>{referenceLabel}</b>
      </Typography>
      <RadioButtonGroupInput
        source={source}
        label={""}
        fullWidth={true}
        choices={[
          { id: "not_verified", name: "Pending" },
          { id: "verification_passed", name: "Yes" },
          { id: "verification_failed", name: "No" },
          { id: "more_info_needed", name: "More Info" },
        ]}
        defaultValue="not_verified"
      />
    </div>
  );
};
export default CheckInput;
